<template>
<h1>{{ currentTime }}</h1>
<p v-if="pauseWarning"></p>
</template>

<script>
import { useCurrentTime } from '@/composables/clockTimer';
import { justCurrentMS } from '@/composables/taskTime';
import { computed } from 'vue';

export default{
    name: 'Clock',
    setup(){
        const { currentTime } = useCurrentTime()
        const { currentMS } = justCurrentMS()
        const pauseWarning = computed(() => currentMS.value.ms > 0)
        return{
            currentTime,
            pauseWarning
        }
    }
}
</script>

<style scoped>
h1{
    margin-top: 0;
    margin-bottom: 0px;
    color: #2c3e50;
    cursor: default;
}
p{
    cursor: default;
    font-style: italic;
    margin-top: 5px;
    margin-bottom: 0px;
}
</style>