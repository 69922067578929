<template>
    <div class="container">
        <div class="btncontainer">
            <button @click="copyToClipBoard" class="custombtn">{{ copyMsg }}</button>
            <button @click="toggleFreeEdit" class="custombtn">{{ freeEditTxt }}</button>
            <button v-if="canCap" @click="capOffTasks" class="custombtn">Cap off session</button>
            <button @click="clearStep" class="custombtn"> {{ clearMsg }}</button>
        </div>
    </div>
</template>

<script>
    import { justCurrentMS, saveShit } from '@/composables/taskTime';
import { ref, computed, onMounted } from 'vue';
export default{
    name: 'CopyButton',
    props:{
        freeEdit:{
            type: Boolean,
            required: true,
            default: true
        }
    },
    emits: ['update:freeEdit'],
    setup(props, {emit}){
        const copyMsg = ref('Copy all to clipboard')
        const { currentMS } = justCurrentMS()
        const { tasksArray, clearSavedData, capOffTasks, tasksCounter, currentCounter } = saveShit()
        const copyToClipBoard = async()  =>{
            try{
                await navigator.clipboard.writeText(tasksArray.value.times)
                changeBtnOnCopy(true)
            } catch(err){
                console.error('Failed to copy: ', err)
                changeBtnOnCopy(false)
            }
        }
        const freeEditTxt = computed(() => {
            if (!props.freeEdit) return 'Edit Tasks Text ON'
            return 'Edit Tasks Text OFF'
        })

        const canCap = computed(() =>{
            if (currentCounter.value > 1 && currentMS.value.ms == 0) return true
            return false
        })
        // Clearing
        const clearMsg = computed(()=>{
            if (clearState.value == 0) return "Clear task area"
            if (clearState.value == 1) return "Click to confirm clear"
        })
        const clearState = ref(0)
        let timeoutId = null
        const autoResetClear = () =>{ 
            timeoutId = setTimeout(() => {
                clearState.value = 0
            }, 2000)
            }
        const cancelAutoReset = () =>{
            if (autoResetClear){
                clearTimeout(timeoutId)
                timeoutId = null
            }
        }
        const clearStep = () => {
            switch (clearState.value){
                case 0:
                    clearState.value++
                    autoResetClear()
                    return;
                case 1:
                    cancelAutoReset()
                    clearSavedData()
                    clearState.value = 0
                    return;
            }
        }

        // stuff
        const toggleFreeEdit = () => {
            emit('update:freeEdit', !props.freeEdit)
        }

        const changeBtnOnCopy = wasGood =>{
            if (wasGood){
                copyMsg.value = 'Copy successful!'
            }else{
                copyMsg.value = 'Copy unsuccessful'
            }
            setTimeout(() =>{
                copyMsg.value = 'Copy all to clipboard'
            },1500)
        }

        onMounted(() =>{
            toggleFreeEdit()
        })
        return{
            copyToClipBoard,
            copyMsg,
            clearSavedData,
            toggleFreeEdit,
            freeEditTxt,
            capOffTasks,
            canCap,
            clearStep,
            clearMsg
        }
    }
}
</script>

<style scoped>
.container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.btncontainer{
    display: flex;
    justify-content: space-between;
    width: 400px;
}
.custombtn{
    width: 100px;
    height: 50px;
    cursor: pointer;
}
</style>