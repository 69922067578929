import { ref, onMounted, onUnmounted, readonly } from 'vue';
const currentTime = ref('')
export function useCurrentTime() {
    const updateTime = () => {
        const now = new Date()
        const hours = now.getHours()
        const minutes = now.getMinutes()
        const seconds = now.getSeconds()
        const ampm = hours >= 12 ? 'PM' : 'AM'
        const formattedHours = hours % 12 || 12 // Convert 0 to 12 for 12 AM

        currentTime.value = `${padZero(formattedHours)}:${padZero(minutes)}:${padZero(seconds)} ${ampm}`
    }
    const padZero = (num) => {
        return num.toString().padStart(2, '0')
    }
    let intervalId
    onMounted(() => {
        updateTime() // Initial update
        intervalId = setInterval(updateTime, 1000)
    })
    onUnmounted(() => {
        clearInterval(intervalId)
    })
    return {
        currentTime: readonly(currentTime)
    }
}