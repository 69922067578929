import { ref, reactive, onMounted, readonly } from "vue";

const FALLBACKLINK = 'crPl0ITIkS0'
const savedVolume = ref(15)
const defaultSong = ref('v-4pqHjpguE')


const songsMap = reactive(new Map())

const addToSongsList = url => {
    if (songsMap.has(url)) return
    songsMap.set(url, 'Attempting to get song name...')
}

const loadSongs = () => {
    songsMap.set('v-4pqHjpguE', 'Walkin On The Sun')
    songsMap.set(FALLBACKLINK, 'You See This On Invalid Link')
    songsMap.set('hM9u0m-yP0o', 'Macroblank - 至福を通じた平和')
    songsMap.set('3UIwvmho8II', 'Lofi for Rainy Days Chillhop')
    songsMap.set('DZyYapMZSec', 'Through The Fire And Flames')
    const storedVol = localStorage.getItem('volume')
    const storedMap = localStorage.getItem('songsMap')
    if (storedVol) {
        savedVolume.value = storedVol
    }
    if (storedMap) {
        const entries = JSON.parse(storedMap)
        console.log(entries)
        songsMap.clear()
        entries.forEach(([key, value]) => {
            songsMap.set(key, value)
        })
    }
}

const printSongsList = () => {
}

const parseFromSwitch = url => {
    let split
    let songId
    let norm = "youtube.com/watch?v="
    let shortNorm = "youtu.be/"
    let embed = "youtube.com/embed/"
    let short = "youtube.com/shorts/"
    switch (true) {
        case url.includes(norm):
            split = url.split(norm)
            songId = split[1].slice(0, 11)
            addToSongsList(songId)
            return songId
        case url.includes(shortNorm):
            split = url.split(shortNorm)
            songId = split[1].slice(0, 11)
            addToSongsList(songId)
            return songId
        case url.includes(embed):
            split = url.split(embed)
            songId = split[1].slice(0, 11)
            addToSongsList(songId)
            return songId
        case url.includes(short):
            split = url.split(short)
            songId = split[1].slice(0, 11)
            addToSongsList(songId)
            return songId
    }
    return FALLBACKLINK
}

export function ytValidate() {
    const getVidId = fullUrl => {
        return parseFromSwitch(fullUrl)
    }

    const clearSongs = () => {
        savedVolume.value = 15
        localStorage.removeItem('volume')
        localStorage.removeItem('songsMap')
        songsMap.clear()
        songsMap.set('v-4pqHjpguE', 'Walkin On The Sun')
        songsMap.set(FALLBACKLINK, 'You See This On Invalid Link')
        songsMap.set('hM9u0m-yP0o', 'Macroblank - 至福を通じた平和')
        songsMap.set('3UIwvmho8II', 'Lofi for Rainy Days Chillhop')
        songsMap.set('DZyYapMZSec', 'Through The Fire And Flames')
    }

    const setVolume = volume => {
        savedVolume.value = volume
        localStorage.setItem('volume', volume)
    }

    const loadYoutubeVideo = (player, videoId, volume) => {
        player.loadVideoById(videoId)
        player.unMute()
        player.setVolume(volume)
        setTimeout(() => {
            if (player.getVideoData().title) {
                let title = player.getVideoData().title
                songsMap.set(videoId, title)
                console.log(songsMap.get(videoId))
                localStorage.removeItem('songsMap')
                localStorage.setItem('songsMap', JSON.stringify(Array.from(songsMap.entries())))
                console.log(songsMap)
            }
        }, 1850)
    }

    const setDefaultVideo = (videoId) => {
        defaultSong.value = videoId
        //console.log(`song is now ${songsMap[videoId]}`)
    }


    onMounted(() => {
        loadSongs()
    })

    return {
        setVolume,
        getVidId,
        clearSongs,
        savedVolume: readonly(savedVolume),
        defaultSong: readonly(defaultSong),
        songsMap: readonly(songsMap),
        loadYoutubeVideo,
        setDefaultVideo
    }
}