<template>
    <SongsLayout/>
</template>
<script>
import SongsLayout from '@/components/SongsLayout.vue';

export default{

    name: 'MyMusicView',
    components: {
    SongsLayout,
    }
}
</script>
<style></style>