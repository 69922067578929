<template>
  <div>
    <!--- img :src="thumbnail" alt="'thumbnail go here'" class="thumbnail" --->
    <input type="text" id="ytUrl" class="url" 
    placeholder="Enter a Youtube link to embed"
    @keyup.enter="handleEnter"
    v-model="urlTxt">
    <div :id="playerId"></div>
    <p class="volumeBar">Volume - {{ volume }}%</p>
    <input type="range" min="0" max="100" v-model="volume" placeholder="e" @mouseup="changeVolume" class="slider">
  </div>
</template>

<script>
import { ytValidate } from '@/composables/ytValidate';
import { ref, watch, onMounted, onUnmounted, computed } from 'vue'

export default {
  name: 'YouTubeTimerPlayer',
  props: {
    timerValue: {
      type: Number,
      required: true
    },
    offsetValue: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      default: 640
    },
    height: {
      type: Number,
      default: 390
    },
    timesUp:{
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    //Url stuff
    const { savedVolume, setVolume, getVidId, loadYoutubeVideo, defaultSong } = ytValidate()
    const urlTxt = ref('')
    const vidId = ref(defaultSong.value)
    const handleEnter = async () => {
      let newId = getVidId(urlTxt.value)
      loadYoutubeVideo(player.value, newId, volume.value)
      vidId.value = newId
      urlTxt.value = ''
    }

    const volume = ref(savedVolume.value)

    //Thumbnail
    const thumbnail = computed(() => `https://img.youtube.com/vi/${vidId.value}/hqdefault.jpg`)
    
    //Youtube init stuff
    const player = ref(null)
    const playerId = `youtube-player-${Math.random().toString(36).substr(2, 9)}`
    const isPlayerReady = ref(false)
    const loadYouTubeIframeAPI = () => {
      return new Promise((resolve) => {
        if (window.YT && window.YT.Player) {
          resolve()
        } else {
          const tag = document.createElement('script')
          tag.src = 'https://www.youtube.com/iframe_api'
          const firstScriptTag = document.getElementsByTagName('script')[0]
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
          window.onYouTubeIframeAPIReady = resolve
        }
      })
    }

    const initPlayer = () => {
      player.value = new YT.Player(playerId, {
        height: props.height,
        width: props.width,
        videoId: vidId.value,
        playerVars: {
          autoplay: 1,
          mute: 1,
          controls: 1,
        },
        events: {
          onReady: onPlayerReady,
        }
      })
    }

    const onPlayerReady = () => {
      isPlayerReady.value = true
      // Initial pause
      player.value.playVideo()
    }

    const changeVolume = () =>{
        player.value.unMute()
        player.value.setVolume(volume.value)
        setVolume(volume.value)
    }

    const controlVideoPlayback = (timesUp) => {
      if (!isPlayerReady.value) return

      if (timesUp) {
        player.value.seekTo(0) // Seek to 00:00
        player.value.playVideo()
        player.value.unMute()
        player.value.setVolume(volume.value)
      } else {
        player.value.pauseVideo()
      }
    }

    watch(() => props.timesUp, (newValue) => {
      controlVideoPlayback(newValue)
    })

    onMounted(async () => {
      await loadYouTubeIframeAPI()
      initPlayer()
      volume.value = savedVolume.value
    })

    onUnmounted(() => {
      if (player.value) {
        player.value.destroy()
      }
    })

    return {
      playerId,
      changeVolume,
      handleEnter,
      urlTxt,
      thumbnail,
      vidId,
      volume
    }
  }
}
</script>

<style scoped>
p.timer{
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  white-space: no-wrap;
  vertical-align: middle;
  text-overflow: ellipsis;
}
p.volumeBar{
  margin: 0;
  font-size: 20px;
  cursor: default;
}
input.url{
  margin-top: 15px;
  width: 375px;
  height: 20px;
}
input.url::placeholder{
  text-align: center;
font-style: italic;
}
input.slider{
  width: 400px;
  height: 30px;
  margin-top: 0px;
  appearance: none;
  background: #4c4c4c;
}
img.thumbnail{
  width: 150px;
}
.slider::-webkit-slider-thumb{
  width: 32px;
  height: 32px;
  appearance: none;
  background: #876edb;
  cursor: pointer;
  box-shadow: 
  -1px -1px 0 #303030,
  1px -1px 0 #303030,
  -1px 1px 0 #303030,
  1px 1px 0 #303030;
}
</style>
