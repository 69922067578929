import { ref, readonly, onMounted, onUnmounted, computed, reactive } from "vue";

const taskTimeMS = ref({ ms: 0 })
const currentMS = ref({ ms: 0 })
const tasksArray = reactive([({ tasknum: '', time: '', len: '' })])
const tasksCounter = ref(1)
const currentCounter = ref(1)
const doneTask = ref(true)
const STORAGE_TASKTXT = 'TEXT'
const STORAGE_TASKCOUNT = 0
const timerOn = ref(false)

const getToday = () => {
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth()
    let day = date.getDay()
    console.log(day)
    console.log(month)
    console.log(year)
    return `${month}/${day}/${year}\n`
}
const genTaskTag = m => {
    let now = formattedTime(new Date())
    return ({ tasknum: `TASK ${tasksCounter.value} `, time: `${now}`, len: ` est ${m}m\n` })
}

const finishClock = computed(() => {
    let now = new Date()
    let nowMs = now.getMilliseconds()
    let finishDate = new Date()
    finishDate.setMilliseconds(taskTimeMS.value)
    return formattedTimeWithSeconds(finishDate)
})
const genDoneTag = () => {
    let now = formattedTime(new Date())
    if (tasksArray.length == 0) return ''
    return ({ tasknum: "DONE ", time: `${now}`, len: '\n' })
}

const cancelTask = () => {
    tasksArray.pop()
    tasksCounter.value = 1
    currentCounter.value = 1
    currentMS.value.ms = 0
    doneTask.value = true
    saveToStorage()
}

const timeOverCheck = () => {
    if (doneTask.value) document.title = "DONE"
    if (tasksCounter.value == 1) {
        document.title = "The Task Timer"
        return
    }
    if (currentMS.value.ms == 0) {
        document.title = "DONE"
        doneTask.value = true
        return
    }
    currentMS.value.ms -= 1000
    if (currentMS.value.ms == 0) {
        doneTask.value = true
        return
    } else {
        document.title = mmssCountdown(currentMS.value.ms)
        return
    }
}

const mmssCountdown = ms => {
    let date = new Date(ms)
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    if (minutes < 10) minutes = '0' + minutes
    if (seconds < 10) seconds = '0' + seconds
    return `${minutes}:${seconds}`
}
const formattedTime = now => {
    const hours = now.getHours()
    const minutes = now.getMinutes()
    const seconds = now.getSeconds()
    const ampm = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12 // Convert 0 to 12 for 12 AM

    return `${padZero(formattedHours)}:${padZero(minutes)} ${ampm}`
}
const formattedTimeWithSeconds = now => {
    const hours = now.getHours()
    const minutes = now.getMinutes()
    const seconds = now.getSeconds()
    const ampm = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12 // Convert 0 to 12 for 12 AM

    return `${padZero(formattedHours)}:${padZero(minutes)}:${padZero(seconds)} ${ampm}`
}
const isValidNumber = time => {
    return !isNaN(time)
}
const padZero = (num) => {
    return num.toString().padStart(2, '0')
}

const saveToStorage = () => {
    localStorage.setItem(STORAGE_TASKTXT, JSON.stringify(tasksArray))
    localStorage.setItem(STORAGE_TASKCOUNT, tasksCounter.value)
}

const loadFromStorage = () => {
    const storedTxt = localStorage.getItem(STORAGE_TASKTXT)
    const storedCount = localStorage.getItem(STORAGE_TASKCOUNT)
    console.log(`storedTxt is ${storedTxt}`)
    if (storedTxt) {
        const parsed = JSON.parse(storedTxt)
        tasksArray.splice(0, tasksArray.length, ...parsed)
    }
    if (storedCount) {
        tasksCounter.value = parseInt(storedCount)
    }
    console.log(tasksArray.length)
    console.log(tasksArray)
}
export function saveShit() {
    const clearSavedData = () => {
        localStorage.removeItem(STORAGE_TASKTXT)
        localStorage.removeItem(STORAGE_TASKCOUNT)
        tasksCounter.value = 1
        currentCounter.value = 1
        if (tasksArray.length == 0) return
        tasksArray.splice(0)
        tasksArray.push(({ tasknum: '', time: '', len: '' }))
    }
    const capOffTasks = () => {
        tasksArray.push(genDoneTag())
        currentCounter.value = 1
    }
    return {
        tasksArray: readonly(tasksArray),
        clearSavedData,
        capOffTasks,
        tasksCounter: readonly(tasksCounter),
        currentCounter: readonly(currentCounter)
    }
}

export function justCurrentMS() {
    return { currentMS: readonly(currentMS) }
}

export function taskTimeSetting() {
    // Timer setter
    const setTheTime = (time, offset) => {
        if (!isValidNumber(offset) && offset !== '') {
            return
        }
        if (offset === '') offset = 0
        if (isValidNumber(time)) {
            if (time == 0) return
            if (tasksCounter.value == 1) {
                tasksArray[0] = genTaskTag(time)
            } else {
                tasksArray.push(genTaskTag(time))
            }
            tasksCounter.value += 1
            currentCounter.value += 1
            doneTask.value = false
            taskTimeMS.value = (time * 60 * 1000) - (offset * 1000)
            currentMS.value.ms = (time * 60 * 1000) - (offset * 1000)
            console.log("adding")
            saveToStorage()
        }
        else {
            console.log('invalid')
            currentMS.value.ms = -1
        }
    }
    const setTaskNum = num => {
        if (isNaN(num)) return
        if (num <= 0) return
        tasksCounter.value = num
        saveToStorage()
        doneTask.value = false
        document.getElementById('setTask').value = ''
        document.getElementById('setTask').placeholder = `Task last set to ${num}`
    }
    const listedView = computed(() => {
        let list = ''
        tasksArray.forEach(thing => {
            list += thing.tasknum + thing.time + thing.len
        });
        return list
    })

    const firstTime = computed(() => {
        if (tasksArray.length == 0) return 'n/a'
        console.log(tasksArray[0])
        return tasksArray[0].time
    })

    const lastTime = computed(() => {
        if (tasksArray.length == 0) return 'n/a'
        console.log(tasksArray[tasksArray.length - 1])
        return tasksArray[tasksArray.length - 1].time
    })
    // Check ticker
    let intervalId
    onMounted(() => {
        intervalId = setInterval(timeOverCheck, 1000)
        loadFromStorage()
    })
    onUnmounted(() => {
        clearInterval(intervalId)
    })
    return {
        currentMS: readonly(currentMS),
        tasksArray: readonly(tasksArray),
        tasksCounter: readonly(tasksCounter),
        setTheTime,
        setTaskNum,
        listedView,
        firstTime,
        lastTime,
        finishClock,
        mmssCountdown,
        cancelTask
    }
}

export function trackOn() {
    return {
        doneTask: readonly(doneTask)
    }
}