<template>
    <div class="container">
        <div class="child">
            <h1>
            Alarm Videos
            </h1>
            <p class="subtitle">Click a video to set it as your default alarm.</p>
            <ul>
                <li v-for="[key, value] in songsMap" class="song" :key="key">
                    <button type="button"
                    @click="handleClick(key)"
                    :style="{
                        backgroundImage: `url(${getThumbnail(key)})`
                        }"
                        class="itemButton">
                    </button>
                    <p>{{ songsMap.get(key) }}</p>
                </li>
            </ul>
        </div>
        <div class="child">
            <Transition name="fade">
            <p class="notify" v-if="showOnChange">Changed alarm to {{ currentSongName }}</p>
            </Transition>
        </div>
        <div class="other">
            <button type="button" @mouseup="clearStep" class="clear">{{ clearMsg }}</button>
        </div>
    </div>
</template>

<script>
import { ytValidate } from '@/composables/ytValidate';
import { computed, reactive, ref } from 'vue';

export default{
    name: 'SongsLayout',
    setup(){
        const {clearSongs, setDefaultVideo, songsMap} = ytValidate()
        const getThumbnail = url =>{
            return `https://img.youtube.com/vi/${url}/hqdefault.jpg`
        }
        const showOnChange = ref(false)
        const clearState = ref(0)
        const currentSongName = ref('')
        const clearMsg = computed(()=>{
            if (clearState.value == 0) return "Clear songs"
            if (clearState.value == 1) return "Click to confirm clear (2s to confirm)"
        })
        let timeoutId = null
        const autoResetClear = () =>{ 
            timeoutId = setTimeout(() => {
                clearState.value = 0
            }, 2000)
            }
        const cancelAutoReset = () =>{
            if (autoResetClear){
                clearTimeout(timeoutId)
                timeoutId = null
            }
        }
        const clearStep = () =>{
            switch (clearState.value){
                case 0:
                    clearState.value++
                    autoResetClear()
                    return;
                case 1:
                    cancelAutoReset()
                    clearSongs()
                    clearState.value = 0
                    return;
            }
        }
        const handleClick = videoId => {
            console.log(songsMap)
            console.log(songsMap.get(videoId))
            setDefaultVideo(videoId)
            currentSongName.value = songsMap.get(videoId)
            showOnChange.value = true
            cancelResetShow()
            autoResetShow()
        }
        let showTimeout;
        const autoResetShow = () =>{ 
            showTimeout = setTimeout(() => {
                showOnChange.value = false
            }, 2000)
            }
        const cancelResetShow = () =>{
            if (autoResetClear){
                clearTimeout(showTimeout)
                showTimeout = null
            }
        }
        return{
            getThumbnail,
            clearSongs,
            clearMsg,
            clearStep,
            setDefaultVideo,
            handleClick,
            currentSongName,
            showOnChange,
            songsMap
        }
    }
}
</script>

<style scoped>
.container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
}
.child{
    width: 40%;
    height: 70%;
    justify-content: center;
    box-shadow: 
    15px 15px 0 #303030,
    -2px -2px 0 #303030,
    -2px 2px 0 #303030,
    2px -2px 0 #303030;
}
ul{
    display: flex;
    flex-wrap: wrap;
    width: 75%;
    justify-content: space-between;
    overflow: hidden;
    padding-left: 12.5%;
    padding-right: 12.5%;
}
li.song{
    display: block;
    width: 150px;
    height: 150px;
}
.itemButton{
    width: 150px;
    height: 75px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    box-shadow: 
    7px 7px 0 #303030,
    0px 0px 0 #303030,
    0px 0px 0 #303030,
    2px -2px 0 #303030;
}
.other{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100px;
    box-shadow: 
    15px 15px 0 #303030,
    -2px -2px 0 #303030,
    -2px 2px 0 #303030,
    2px -2px 0 #303030;
}
.clear{
    width: 150px;
    height: 75px;
}
img{
    width: 150px;
    height: 75px;
}
h1{
    cursor: default;
}
p{
    cursor: default;
}
p.notify{
    font-weight: bold;
}
p.subtitle{
    font-style: italic;
}

.fade-enter-active,
.fade-leave-active{
    transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to{
    opacity: 0;
}
</style>