<template>
    <div class=container>
        <div class="child">
            <YoutubeSet
            :timer-value="timerValue" 
            :width="400" 
            :height="250" 
            :timesUp="timesUp"
            :offsetValue="offsetValue"
            />
            <div class="timerOn">
                <h3 v-if="timesUp" class="timeoff">Timer OFF</h3>
                <h3 v-else class="timeon">{{ mmssTxt }} - ENDING AT {{ finishClock }} </h3>
            </div>
           
            <div class="startend">
                <p v-if="hasTasks" class="sechild">First: {{ firstTime }}</p>
                <p v-if="hasTasks" class="sechild">Last: {{ lastTime }}</p>
            </div>
            <div class="taskset">
                <input 
                v-model="timerValue"
                id="taskInput"
                type="text" 
                class="time"
                @keyup.enter="handleEnter"
                placeholder="Task Time in minutes">
                <input 
                v-model="offsetValue"
                id="offsetInput"
                type="text" 
                class="offset"
                @keyup.enter="handleEnter"
                placeholder="Offset in seconds">
            </div>
            <p class="timer">{{ currTimerSwitch }} {{ timerValue }} {{ timerString }} {{ offsetString }}{{ offsetValue }} {{ offsetString2 }}</p>
            <div>
            <button class="cancel" v-if="!timesUp" @click="cancelTask">Cancel Current Timer</button>
            <textarea 
            name="" 
            id=""
            spellcheck="false"
            :readonly="freeEdit"
            placeholder="Tasks will show up here"
            >{{ listedView }}</textarea>
            </div>
            <input class="taskNumInput" placeholder="Set current task number" v-model="taskNum" type="text" id="setTask" @keyup.enter="handleTaskNum">
            <CopyButton
            v-model:freeEdit="freeEdit"/>
        </div>
    </div>

</template>

<script>
import {HackTimer, HackTimerWorker} from 'hacktimer'
import { ref, computed } from 'vue';
import { taskTimeSetting, trackOn } from '@/composables/taskTime';
import YoutubeSet from './YoutubeSet.vue';
import Clock from './Clock.vue';
import CopyButton from './CopyButton.vue';
export default{
    name: 'TaskSet',
    components: {
        YoutubeSet,
        Clock,
        CopyButton
    },
    setup(){
        const taskNum = ref('')
        const freeEdit = ref(false)
        const timerValue = ref('')
        const offsetValue = ref('')
        const {cancelTask, mmssCountdown, finishClock, firstTime, lastTime, currentMS, setTheTime, tasksArray, setTaskNum, listedView} = taskTimeSetting()
        const {doneTask} = trackOn()
        const timesUp = computed(() => currentMS.value.ms === 0)
        const handleEnter = () =>{
            setTheTime(timerValue.value, offsetValue.value)
        }

        const handleTaskNum = () =>{
            let num = taskNum.value
            setTaskNum(num)
        }

        const hasTasks = computed(() =>{
            if (tasksArray.length == 0) return ''
            return tasksArray[0].tasknum !== '' 
        })

    const timerString = computed(() => {
      if (timerValue <= 0) return ''
      if (timerValue == 1) return 'minute'
      return 'minutes'
    }) 

    const currTimerSwitch = computed(() => {
      if (timerValue <= 0) return ''
      return 'Current Timer:'
    })

    const offsetString = computed(() => {
      if (offsetValue <= 0) return ''
      return '(triggers '
    })
    const offsetString2 = computed(() =>{
      if (offsetValue <= 0) return ''
      if (offsetValue == 1) return 'second early)'
      return 'seconds early)'
    })

    const mmssTxt = computed(() =>{
        return mmssCountdown(currentMS.value.ms)
    })
        return{
            mmssCountdown,
            mmssTxt,
            finishClock,
            timerString,
            currTimerSwitch,
            offsetString,
            offsetString2,
            timerValue,
            currentMS,
            setTheTime,
            timesUp,
            handleEnter,
            tasksArray,
            offsetValue,
            freeEdit,
            taskNum,
            handleTaskNum,
            setTaskNum,
            listedView,
            firstTime,
            lastTime,
            hasTasks,
            cancelTask
        }
    },
}
</script>

<style scoped>
.child{
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, #aeaeae, #616161);
    box-shadow: 
    15px 15px 0 #303030,
    -2px -2px 0 #303030,
    -2px 2px 0 #303030,
    2px -2px 0 #303030;
    width: 480px;
    height: 740px;
}
.child2{
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, #aeaeae, #616161);
    box-shadow: 
    15px 15px 0 #303030,
    -2px -2px 0 #303030,
    -2px 2px 0 #303030,
    2px -2px 0 #303030;
    width: 480px;
    height: 375px;
}
.sechild{
    padding: 0px;
    margin: 0px;
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}
.taskset{
    margin-top: 0px;
}
p{
    cursor: default;
}
p.tabWarning{
    font-style: italic;
}
input.time{
    width: 150px;
    text-align: center;
}
input.offset{
    margin-left: 10px;
    width: 120px;
    text-align: center;
}
.timerOn{
    margin-bottom: 0px;
}
h3{
    margin: 0px;
    cursor: default;
}
.timeon{
    color: #33e766;
}
.timeoff{
    color: #f31515;
}
textarea{
    resize: none;
    width: 400px;
    height: 150px;
}
textarea::placeholder{
    font-style: italic;
}
.taskNumInput::placeholder{
    font-style: italic;
    text-align: center;
}
.taskNumInput{
    font-size: 16px;
    margin-top: 0px;
    text-align: center;
    align-self: center;
    width: 395px;
    height: 25px;
}
.startend{
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 20%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
button.cancel{
    width: 225px;
    height: 35px;
}
</style>