<template>
  <div class="container">
    <div class="home">
      <h1>the TASK TIMER</h1>
      <Clock/>
    </div>
  </div>
  <TaskSet/>
</template>

<script>
// @ is an alias to /src
import Clock from '@/components/Clock.vue';
import TaskSet from '@/components/TaskSet.vue';

export default {
  name: 'HomeView',
  components: {
    TaskSet,
    Clock
  }
}
</script>

<style scoped>
p{
  margin: 0%;
  font-size: 10px;
  font-style: italic;
}
div.container{
  display: flex;
align-items: center;
justify-content: center;
padding-bottom: 25px;
}
div.home{
  width: 480px;
  height: 125px;
  justify-content:start;
  background-image: linear-gradient(to bottom, #aeaeae, #616161);
  box-shadow: 
  15px 15px 0 #303030,
  -2px -2px 0 #303030,
  -2px 2px 0 #303030,
  2px -2px 0 #303030;
}
h1{
  margin-bottom: 0;
  color: #5690d6;
  text-shadow:
  -1px -1px 0 #2c3e50,
  1px -1px 0 #2c3e50,
  -1px 1px 0 #2c3e50,
  1px 1px 0 #2c3e50;
  cursor: default;
}
</style>