<template><div>
    <h1>How do I add a video?</h1>
    <p>A valid Youtube link requires its 11 character ID and one of the following prefixes.</p>
    <p>youtube.com/watch?v= XXXXXXXXXXX</p>
    <p>youtu.be/</p>
    <p>youtube.com/embed/</p>
    <p>youtube.com/shorts/</p>
    <img src="@/assets/placeholder.png" alt="">
    <p class="info">If a video failed to add, it'll show the placeholder video.</p>
</div></template>
<script>
</script>
<style>
p{
    cursor:default;
}
</style>